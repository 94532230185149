var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import EventsSearchContext from "../../../application/Context/EventsSearchContext";
export var InfoBar = function () {
    var _a = React.useContext(EventsSearchContext), count = _a.count, view = _a.view;
    var t = useTranslation(["news"]).t;
    return (_jsx(_Fragment, { children: view == "list" && (_jsx("div", __assign({ className: "under-filter-info" }, { children: _jsxs("div", { children: [count, " ", t("éléments")] }) }))) }));
};
