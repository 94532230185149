var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { Title } from "../../shared-components/Title/Title";
import { useTranslation } from "react-i18next";
import EventsSearchContext from "../../../../application/Context/EventsSearchContext";
export var ViewToggler = function () {
    var t = useTranslation(["common"]).t;
    var _a = useContext(EventsSearchContext), view = _a.view, handleView = _a.handleView;
    return (_jsx(React.Fragment, { children: _jsxs("div", __assign({ className: "d-flex justify-content-between align-items-center" }, { children: [_jsx(Title, { title: t('Événements à venir') }), _jsxs("div", __assign({ className: "d-flex gap-2" }, { children: [_jsx("div", __assign({ onClick: handleView }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/f-list").concat(view == "list" ? "-active" : "", ".svg"), alt: "list icon" }) })), _jsx("div", __assign({ onClick: handleView }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/calendar-full").concat(view == "calendar" ? "-b" : "-g", ".svg"), alt: "calendar icon" }) }))] }))] })) }));
};
