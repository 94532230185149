var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { FilterCard } from "../components/react-components/shared-components/SideFilter/FilterCard";
import { Container, FiltersContainer, ContentContainer, } from "../components/react-components/shared-components/Ui/Grid/Grid";
import { InfoBar } from "../components/react-components/events-components/InfoBar";
import { Views } from "../components/react-components/events-components/Views/Views";
import { EventsSearchProvider } from "./Context/EventsSearchContext";
import { Filters } from "../components/react-components/events-components/Filters";
import { ViewToggler } from "../components/react-components/events-components/Views/ViewToggler";
import { SearchBar } from "../components/react-components/events-components/SearchBar";
import { CleanFilters } from "../components/react-components/events-components/CleanFilters";
import { Pagination } from "../components/react-components/events-components/Pagination";
import Theme from "../components/react-components/Styles/Theme";
import { ThemeProvider } from "styled-components";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import i18n from "../i18n/i18n";
import { PageLoading } from "../components/react-components/shared-components/Loading/PageLoading";
var Events = function () {
    React.useEffect(function () {
        var _a;
        var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
        if ((langCode === null || langCode === void 0 ? void 0 : langCode.length) === 2) {
            i18next.changeLanguage(langCode);
        }
    }, []);
    return (_jsx(Suspense, __assign({ fallback: _jsx(PageLoading, {}) }, { children: _jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(EventsSearchProvider, { children: _jsx(ThemeProvider, __assign({ theme: Theme }, { children: _jsx("div", __assign({ className: "pg-news" }, { children: _jsxs(Container, { children: [_jsxs(FiltersContainer, { children: [_jsx(SearchBar, {}), _jsxs(FilterCard, { children: [_jsx(CleanFilters, {}), _jsx(Filters, {})] })] }), _jsxs(ContentContainer, __assign({ breakPoint: "md", col: "9" }, { children: [_jsx(ViewToggler, {}), _jsx(InfoBar, {}), _jsx(Views, {}), _jsx(Pagination, {})] }))] }) })) })) }) })) })));
};
var domNode = document.getElementById("events");
if (domNode) {
    var root = createRoot(domNode);
    root.render(_jsx(Events, {}));
}
