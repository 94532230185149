import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { EventsListView } from "./EventsListView";
import { CalendarView } from "./CalendarView";
import { useContext } from "react";
import EventsSearchContext from "../../../../application/Context/EventsSearchContext";
export var Views = function () {
    var view = useContext(EventsSearchContext).view;
    return (_jsx(React.Fragment, { children: view === "list" ? _jsx(EventsListView, {}) : _jsx(CalendarView, {}) }));
};
