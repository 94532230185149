var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import { EventsContainer, EventsTitle, DateContainer, Text, Tag, EventsImage, } from "../../Styles/SharedStyledComponents";
import { useTranslation } from "react-i18next";
import EventsSearchContext from "../../../../application/Context/EventsSearchContext";
import { Row } from "../../../../components/react-components/shared-components/Ui/Row";
export var EventsListView = function () {
    var _a;
    var events = useContext(EventsSearchContext).events;
    //language Code
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var t = useTranslation(["events"]).t;
    var options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
    };
    var f = Intl.DateTimeFormat(langCode, options);
    return (_jsx(React.Fragment, { children: events.map(function (event, i) {
            return (_jsx(EventsContainer, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0 mb-3 mb-md-0" }, { children: _jsx("a", __assign({ href: langCode == "fr" ? event.slug_fr + "/" : event.slug_en + "/" }, { children: _jsx(EventsImage, { children: _jsx("img", { src: event.thumbnail ? event.thumbnail : process.env.IMG_URL + "/static/vendors/images/default-Image-company.png", alt: "banner" }) }) })) })), _jsx("div", __assign({ className: "col-md-7" }, { children: _jsxs(Text, { children: [_jsxs(DateContainer, { children: [t("Du"), " : ", f.format(new Date(event.date_debut)), _jsx("br", {}), t("Au"), " : ", f.format(new Date(event.date_fin))] }), _jsxs(EventsTitle, { children: [_jsxs("a", __assign({ href: langCode == "fr" ? event.slug_fr + "/" : event.slug_en + "/" }, { children: [langCode == "fr"
                                                        ? event.titre_event_fr
                                                        : event.titre_event_en, " "] })), " "] }), event.organisateur && (_jsxs("p", { children: [t("Organized by"), " ", event.organisateur] })), _jsxs("div", { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue-fonce.svg"), alt: "location" }), _jsx("span", { children: event.region })] }), _jsx("div", __assign({ className: "d-flex flex-wrap mb-0" }, { children: Object.keys(event.categorie).length > 0 ? (_jsx(Tag, { children: langCode == "fr"
                                                ? event.categorie.nom_fr
                                                : event.categorie.nom_en }, event.categorie.id)) : (_jsx(Tag, { children: t("Uncategorized") })) }))] }) }))] }) }, i));
        }) }));
};
