var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { CalendarWrapper, CalendarNote } from "./Calendar.styles";
import { useTranslation } from "react-i18next";
import EventsSearchContext from "../../../application/Context/EventsSearchContext";
import { Tag } from "../Styles/SharedStyledComponents";
var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
if (langCode === "fr") {
    moment.updateLocale("fr", {
        weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        week: {
            dow: 0,
        },
    });
}
var buildCalendar = function (momentVal, events) {
    var startDay = momentVal.clone().startOf("month").startOf("week");
    var endDay = momentVal.clone().endOf("month").endOf("week");
    var day = startDay.clone().subtract(1, "day");
    var calendar = [];
    while (day.isBefore(endDay, "day")) {
        var week = Array(7)
            .fill(0)
            .map(function () { return day.add(1, "day").clone(); });
        calendar.push(week);
    }
    // Adjust calendar to mark days where events occur
    events.forEach(function (event) {
        var startDate = moment(event.date_debut);
        var endDate = moment(event.date_fin);
        var currentDay = startDate.clone();
        while (currentDay.isSameOrBefore(endDate, "day")) {
            var rowIndex = currentDay.diff(startDay, "weeks");
            var dayIndex = currentDay.weekday();
            if (calendar[rowIndex] && calendar[rowIndex][dayIndex]) {
                calendar[rowIndex][dayIndex].events =
                    calendar[rowIndex][dayIndex].events || [];
                calendar[rowIndex][dayIndex].events.push(event); // Add the event to the day
            }
            currentDay.add(1, "day");
        }
    });
    return calendar;
};
export var Calendar = function () {
    var _a;
    var t = useTranslation(["events"]).t;
    var events = useContext(EventsSearchContext).events;
    // language Code
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var todayMoment = moment();
    var _b = __read(useState(moment()), 2), momentVal = _b[0], setMomentVal = _b[1];
    var _c = __read(useState(moment().format("M")), 2), curMonth = _c[0], setCurMonth = _c[1];
    var _d = __read(useState({ id: null }), 2), noteOpen = _d[0], setNoteOpen = _d[1];
    var _e = __read(useState([]), 2), calendar = _e[0], setCalendar = _e[1];
    useEffect(function () {
        setCalendar(buildCalendar(momentVal, events));
    }, [momentVal, events]);
    /** Start: Calendar logic */
    var Days = moment.weekdaysShort();
    var changeMonth = function (e, isTarget) {
        var newMoment = momentVal.add(isTarget, "M");
        setMomentVal(newMoment);
        setCurMonth(newMoment.format("M"));
        setCalendar(buildCalendar(newMoment, events));
    };
    /** End: Calendar logic */
    var getEventsPerDay = function (day) {
        var arr = [];
        events.forEach(function (event) {
            var startDate = moment(event.date_debut);
            var endDate = moment(event.date_fin);
            // Convert `day` to moment if it's not already
            var dayMoment = moment(day, "DD/MM/YYYY");
            // Check if the day is between start date and end date inclusively
            if (dayMoment.isSameOrAfter(startDate, "day") &&
                dayMoment.isSameOrBefore(endDate, "day")) {
                arr.push(event);
            }
        });
        return arr;
    };
    var toggleNote = function (e, id) {
        var elem = document.getElementById(id);
        setNoteOpen({ id: id });
        if (elem.dataset.open === "true")
            setNoteOpen({ id: null });
    };
    return (_jsx(React.Fragment, { children: _jsx(CalendarWrapper, { children: _jsxs("div", __assign({ className: "calendar-wrapper", id: "events-calendar-wrapper" }, { children: [_jsxs("div", __assign({ className: "c-controllers" }, { children: [_jsx("div", __assign({ className: "month-prev" }, { children: _jsx("button", __assign({ onClick: function (e) { return changeMonth(e, -1); } }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-left-blue.svg"), role: "link", alt: t("left arrow icon") }) })) })), _jsx("div", __assign({ className: "month-current" }, { children: momentVal.format("MMMM YYYY") })), _jsx("div", __assign({ className: "month-next" }, { children: _jsx("button", __assign({ onClick: function (e) { return changeMonth(e, 1); } }, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-right-blue.svg"), role: "link", alt: t("right arrow icon") }) })) }))] })), _jsx("div", __assign({ className: "c-header" }, { children: Days.map(function (dayAbr, key) { return (_jsx("div", __assign({ className: "day-col" }, { children: dayAbr }), key)); }) })), _jsx("div", __assign({ className: "c-body" }, { children: calendar &&
                            calendar.map(function (week, key) { return (_jsx("div", __assign({ className: "c-week" }, { children: week.map(function (day, key) { return (_jsxs("div", __assign({ "data-status": parseInt(curMonth) !== parseInt(day.format("M"))
                                        ? "not-month"
                                        : "", className: "c-day", "data-date": day.format("DD/MM/YYYY") }, { children: [_jsx("div", __assign({ className: "c-day-header" }, { children: _jsx("div", __assign({ className: "date-sq ".concat(todayMoment.format("DD/MM/YYYY") ===
                                                    day.format("DD/MM/YYYY") && "active") }, { children: day.format("D").toString() })) })), _jsx("div", __assign({ className: "c-day-body" }, { children: getEventsPerDay(day.format("DD/MM/YYYY")).map(function (event, key) {
                                                var _a;
                                                return (_jsxs(CalendarNote, __assign({ "data-open": noteOpen.id ===
                                                        "c-note-" + key + "-" + day.format("DD-MM-YYYY")
                                                        ? "true"
                                                        : "false", "data-status": event.status, noteColor: "".concat((_a = event.categorie) === null || _a === void 0 ? void 0 : _a.color), id: "c-note-" + key + "-" + day.format("DD-MM-YYYY") }, { children: [_jsxs("div", __assign({ className: "c-note-header ".concat(noteOpen.id ===
                                                                "c-note-" +
                                                                    key +
                                                                    "-" +
                                                                    day.format("DD-MM-YYYY") && "fw-bold"), onClick: function (e) {
                                                                return toggleNote(e, "c-note-" +
                                                                    key +
                                                                    "-" +
                                                                    day.format("DD-MM-YYYY"));
                                                            } }, { children: [langCode === "fr"
                                                                    ? event.titre_event_fr
                                                                    : event.titre_event_en, _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/chevron-down.svg"), role: "link", alt: t("down arrow icon") })] })), _jsxs("div", __assign({ className: "c-note-body" }, { children: [event.organisateur && (_jsxs("div", __assign({ className: "mt-2" }, { children: [" ", t("Organized by"), " ", event.organisateur] }))), _jsx("div", { children: _jsxs("a", __assign({ href: langCode === "fr"
                                                                            ? event.absolute_url_fr
                                                                            : event.absolute_url_en }, { children: [t("Details"), _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/arrow-right-small.svg"), role: "link", alt: t("right arrow icon") })] })) }), _jsx("div", __assign({ className: "d-flex flex-wrap mb-4" }, { children: [event.categorie].map(function (cat) { return (_jsx(Tag, { children: langCode === "fr"
                                                                            ? cat.nom_fr
                                                                            : cat.nom_en }, cat.id)); }) })), _jsxs("div", __assign({ className: "c-note-body-footer" }, { children: [_jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/clock.svg"), role: "link", alt: t("clock icon") }), "".concat(new Date(event.date_debut)
                                                                                    .getHours()
                                                                                    .toString()
                                                                                    .padStart(2, "0"), ":").concat(new Date(event.date_debut)
                                                                                    .getMinutes()
                                                                                    .toString()
                                                                                    .padStart(2, "0"), "  ")] })), _jsxs("div", __assign({ className: "d-flex align-items-center" }, { children: [_jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/map-marker-blue-fonce.svg"), role: "link", alt: t("map marker icon") }), event.region] }))] }))] }))] }), key));
                                            }) }))] }), key)); }) }), key)); }) }))] })) }) }));
};
